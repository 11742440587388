/* LandingPage.css */

:root{
  --primary-err-color: #FF2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #F2F2F2;
  --disable-bg-color: #606060;
} 

html[data-theme="penta"]{
  --primary-theme-color: rgba(93,188,210);
  --primary-theme-color-half: rgba(93,188,210, 0.5);
  --primary-theme-color-08: rgba(93,188,210, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(93,188,210, 0.2);
  --light-bg-color: rgba(93,188,210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0,42,132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
}

html[data-theme="penguin"]{
  --primary-theme-color: rgba(35,153,229);
  --primary-theme-color-half: rgba(35,153,229, 0.5);
  --primary-theme-color-08: rgba(35,153,229, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(35,153,229, 0.2);
  --light-bg-color: rgba(35,153,229, 0.3);
  --dark-bg-color: rgba(35,153,229);
  --dark-bg-color-07: rgba(35,153,229, 0.7);
  --chat-bubble-color: rgba(35,153,229, 0.2);
  --chat-bubble-color-selected: rgba(35,153,229, 0.4);
  --primary-err-color: #FF4D4D;
  --std-border: 0;
}

.LandingPage{
  background-color: var(--primary-theme-color);
  height: 100vh;
  color: var(--primary-font-color);
  overflow: auto;
  overflow-x: hidden;
}

.LandingPage .detail{
  padding: 10% !important;
  padding-right: 2% !important;
  font-size: 20px;
  line-height: 1.6em;
}

.LandingPage .detail .header{
  color: var(--primary-font-color);
  font-size: 50px;
}

.LandingPage .pic{
  padding: 10% !important;
  padding-left: 2% !important;
}

.LandingPage .detail .header.darkblue{
  color: var(--header-font-color, --primary-font-color);
}

.LandingPage .detail .whiteBasicButton{
  background-color: transparent; 
  color: var(--primary-font-color); 
  border: 2px solid var(--primary-font-color);
}