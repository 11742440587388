/* ChatTable.css */

:root {
  --primary-err-color: #ff2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #f2f2f2;
  --disable-bg-color: #606060;
}

html[data-theme="penta"] {
  --primary-theme-color: rgba(93, 188, 210);
  --primary-theme-color-half: rgba(93, 188, 210, 0.5);
  --primary-theme-color-08: rgba(93, 188, 210, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(93, 188, 210, 0.2);
  --light-bg-color: rgba(93, 188, 210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0, 42, 132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
}

html[data-theme="penguin"] {
  --primary-theme-color: rgba(35, 153, 229);
  --primary-theme-color-half: rgba(35, 153, 229, 0.5);
  --primary-theme-color-08: rgba(35, 153, 229, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(35, 153, 229, 0.2);
  --light-bg-color: rgba(35, 153, 229, 0.3);
  --dark-bg-color: rgba(35, 153, 229);
  --dark-bg-color-07: rgba(35, 153, 229, 0.7);
  --chat-bubble-color: rgba(35, 153, 229, 0.2);
  --chat-bubble-color-selected: rgba(35, 153, 229, 0.4);
  --primary-err-color: #ff4d4d;
  --std-border: 0;
}

.ChatTable.noMargin {
  margin: 0;
}

.ui.celled.table.ChatTable.noMargin {
  margin: 0;
}

.ChatTable td.chatBox {
  padding: 0;
}

.ChatTable td.chatBox .ChatBox {
  /* height: calc(100vh - 40px); */
}

.ChatTable td.chatList {
  padding: 0;
  vertical-align: top;
}

.ChatTable td.chatDetail {
  padding: 0;
  vertical-align: top;
}

.ChatTable td.chatDetail .ChatDetail {
  /* height: 93vh; */
}

.ChatTable td.chatDetail .PatientPanel {
  /* height: 93vh; */
  width: 100%;
}

.ChatTable td.chatList .ChatList,
.ChatTable td.chatList .ChatList .Dimmer {
  height: calc(100vh - 40px);
}

.ChatTable td.chatList {
  vertical-align: middle;
  /* height: 100%; */
  /* height: 93vh; */
}

.ChatTable .chatDetail .ChatDetail {
  /* height: 93vh; */
}

.ChatTable .chatDetail .PatientPanel,
.ChatTable .chatDetail .PatientPanel .dimmable {
  /* height: 93vh; */
  height: calc(100vh - 40px);
  width: 100%;
}

.ChatTable .chatList .ChatList,
.ChatTable .chatList .ChatList .Dimmer {
  height: calc(100vh - 200px);
}

.ChatTable .chatList .ChatList.hideFilter,
.ChatTable .chatList .ChatList.hideFilter .Dimmer {
  height: calc(100vh - 160px);
}

.ChatTable .chatList .chatListError {
  /* height: 93vh; */
}

.ui.celled.grid.ChatTable.noMargin {
  margin: 0;
}

.ChatTable .chatBox {
  height: calc(100vh - 40px);
}

.ChatTable .chatBox .chat-loader {
  position: relative;
  padding: 20px;
}

.ChatTable .chatbox-header.ui.form .fields {
  justify-content: flex-end;
  background-color: var(--secondary-bg-color);
  margin: 0;
}

.ChatTable .chatbox-header .ui.button {
  background-color: var(--primary-theme-color, black);
  color: var(--primary-font-color, white);
}

.ChatTable .chatbox-header .ui.button.inverted-btn,
.ChatTable .chatbox-header .ui.button.create-appointment {
  color: var(--primary-theme-color);
  border: 2px solid var(--primary-theme-color, black);
  background-color: transparent;
}

.ChatTable .chatbox-header .ui.button.edit-appointment {
  color: var(--primary-err-color);
  border: 2px solid var(--primary-err-color, black);
  background-color: transparent;
}

.ui.modal.classify {
  width: 500px;
}

.ChatTable .PatientPanel .main-segment {
  background-color: var(--primary-theme-color);
  height: 100%;
  overflow: auto;
  margin: 0;
}

.ChatTable .PatientPanel .main-segment .icon.user {
  color: var(--primary-font-color, black);
}

.ChatTable .PatientPanel .main-segment .note-bg {
  background: var(--light-bg-color);
  color: var(--primary-theme-color);
  text-align: left;
  margin-bottom: 10px;
}

.ChatTable .PatientPanel .main-segment .btn-download {
  background: #0072bc;
  padding: 16px 45px;
  color: white;
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.ChatTable .PatientPanel .main-segment .header {
  color: var(--primary-font-color);
}

.ChatTable .PatientPanel .main-segment .header.inverted {
  color: black;
  text-align: left;
}

.ChatTable .PatientPanel .main-segment .left-align {
  margin: 5px;
  text-align: left;
}

.CardLayout.nurse-note .ui.button {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  border: var(--std-border);
}
