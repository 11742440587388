/* MainNurse.css */

:root {
  --primary-err-color: #ff2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #f2f2f2;
  --disable-bg-color: #606060;
  --primary-correct-color: #27ae60;
}

html[data-theme="penta"] {
  --primary-theme-color: rgba(93, 188, 210);
  --primary-theme-color-half: rgba(93, 188, 210, 0.5);
  --primary-theme-color-08: rgba(93, 188, 210, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(93, 188, 210, 0.2);
  --light-bg-color: rgba(93, 188, 210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0, 42, 132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
  --dashboard-label-bg: rgba(234, 246, 252, 0.5);
  --sidebar-light-bg: rgb(221, 244, 250);
}

html[data-theme="penguin"] {
  --primary-theme-color: rgba(35, 153, 229);
  --primary-theme-color-half: rgba(35, 153, 229, 0.5);
  --primary-theme-color-08: rgba(35, 153, 229, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(35, 153, 229, 0.2);
  --light-hover-bg: rgba(35, 153, 229, 0.1);
  --light-bg-color: rgba(35, 153, 229, 0.3);
  --dark-bg-color: rgba(35, 153, 229);
  --dark-bg-color-07: rgba(35, 153, 229, 0.7);
  --chat-bubble-color: rgba(35, 153, 229, 0.2);
  --chat-bubble-color-selected: rgba(35, 153, 229, 0.4);
  --primary-err-color: #ff4d4d;
  --primary-correct-color: #27ae60;
  --std-border: 0;
  --dashboard-label-bg: rgba(234, 246, 252, 0.5);
  --sidebar-light-bg: rgb(221, 244, 250);
}

h1.resolve-chat {
  padding: 5%;
  text-align: center;
}

.header5 {
  font-size: 1rem;
}

.main-nurse {
  height: 100%;
}

button.ui.icon.button.tabButton {
  width: 100%;
  margin: 0;
  border-radius: 0;
  background-color: var(--light-bg-color);
  color: black;
}

button.ui.active.icon.button.tabButton {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}

button.ui.icon.button.tabButton:hover:not(.active) {
  background-color: var(--primary-theme-color-half);
}

.MainScreen .ui.grid .page {
  padding: 0;
  width: calc(100% - 40px);
  height: 100%;
  /* height: calc(100vh - 40px); */
}

.MainScreen .ui.grid .page.hide-sidebar {
  width: 100%;
}

.MainScreen > .ui.grid {
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* height: 93vh; */
}

.MainScreen .ui.grid .tab {
  padding: 0;
  width: 40px;
  border-right: 0.5px solid var(--primary-theme-color);
  background-color: var(--light-hover-bg);
  min-height: calc(100vh - 40px);
  max-height: 100vh;
}

.MainScreen .ui.menu {
  background-color: var(--primary-theme-color);
  margin: 0;
  height: 40px;
  border-bottom: var(--std-border);
}

.MainScreen .ui.menu .ui.button {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}

.MainScreen .ui.menu h2 {
  color: var(--primary-font-color);
  cursor: pointer;
}

.MainScreen .ui.menu .right.item h3 {
  margin: 0;
  color: var(--primary-font-color);
}

.DoctorScheduleView .dateHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
}

.DoctorScheduleView .content {
  padding-left: 1%;
  padding-right: 1%;
}

.DoctorScheduleView .calendarContent {
  height: 77vh;
  max-height: 77vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: solid var(--primary-theme-color) 1px;
}

.DoctorScheduleView .calendarContent::-webkit-scrollbar {
  /* width: 0px; */
  /* background: transparent;  */
  background: var(--primary-theme-color);
  opacity: 0.2;
  /* background: rgba(93,188,210, 0.2); */
}

.DoctorScheduleView .calendarContent::-webkit-scrollbar-thumb {
  /* background: rgba(93,188,210, 0.8); */
  background: var(--primary-theme-color);
  opacity: 0.8;
}

.DoctorScheduleView .dateHeader .button {
  background-color: var(--primary-theme-color);
  opacity: 0.5;
}

.mod-select-appointment.ui.modal .header {
  display: flex;
  justify-content: space-between;
}

.FormAnalytics .triage-level-list {
  background-color: var(--light-bg-color);
  height: 92px;
  width: 117px;
  border-radius: 10px;
  padding: 16px;
  color: gray;
  cursor: pointer;
}

.FormAnalytics .triage-level-list:hover {
  background-color: var(--primary-theme-color-half);
}

.FormAnalytics .triage-level-list.selected {
  background-color: var(--primary-theme-color);
}

.FormAnalytics .ui.ui.button.selected {
  background-color: var(--primary-theme-color);
  color: white;
}

.FormAnalytics .headers {
  background-color: var(--primary-theme-color);
  color: white;
}

.FormAnalytics .items-list {
  padding-top: 0px !important;
  padding-right: 0px !important;
  height: 80vh;
  overflow: auto;
}

.FormAnalytics .items-list .ui.form:hover {
  background-color: var(--primary-theme-color-half);
  opacity: 0.75;
  cursor: pointer;
}

.FormAnalytics .items-list .ui.form.selected {
  background-color: var(--primary-theme-color-half);
}

.FormAnalytics .assessment-form {
  background-color: var(--light-bg-color);
  height: 80vh;
  overflow: auto;
  padding: 0 !important;
}

.FormAnalytics .assessment-form .answer {
  padding: 10px;
  background: white;
  border-radius: 5px;
  margin-bottom: 3px;
  padding-top: 5px;
}

.FormAnalytics .primary {
  color: var(--primary-theme-color);
}

// QAChat

.qa-chat-table,
.qa-chat-modal {
  .font-primary {
    color: var(--primary-theme-color);
  }
  .font-blue,
  .ui.input.font-blue input {
    color: #2f80ed;
  }
  .ui.button.btn-primary {
    background-color: var(--primary-theme-color) !important;
    color: white;
  }
}
.qa-chat-table {
  // padding: 10px;

  * {
    scrollbar-width: thin;
    &:-webkit-scrollbar {
      width: 6px;
    }
    /* webkit browsers */
    &::-webkit-scrollbar {
      width: 6px;
    }
  }

  .ui.button {
    &.primary {
      background-color: var(--primary-theme-color);
      width: 150px;
      color: white;
      border-radius: 6px;
    }

    &.back {
      background-color: #f2994a;
    }
  }

  .profile-form {
    padding: 5px;
    overflow-y: auto;
    height: calc(100vh - 82px);
    .header {
      padding: 5px;
      margin-bottom: -10px;
      // margin-top: 10px;
      margin-left: 5px;
    }
    .inline.field {
      display: flex;
      align-items: center;
    }
  }
  .center-header {
    display: grid;
    grid-template-columns: 25% 75%;
    background: #f2f2f2;
    padding: 2px 10px 1px;
    .ui.button {
      padding: 7px 12px;
      border-radius: 8px;
      margin: 3px 4px;
      margin-bottom: 4px;
      border: 2px solid var(--primary-theme-color);
      &.finish-treatment {
        color: #eb5757;
        border: 2px solid #eb5757;
        background-color: white;
      }
      &.edit-appointment,
      &.create-appointment {
        background-color: white !important;
      }
      &.teleconsult {
        border: 2px solid #27ae60;
        background-color: #27ae60;
      }
      border-width: 1px !important;
    }
    .ui.dropdown {
      padding: 7px 20px;
      border-radius: 8px;
      margin: 3px 4px;
      margin-bottom: 4px;
      min-height: auto !important;
      height: 2.1em;
      width: 100%;
      .text {
        overflow: hidden;
        height: 17px;
        line-height: 20px;
        margin-top: -3px;
      }
      i {
        margin-top: -13px !important;
      }
    }
  }
  .form-label-primary {
    &.ui.form .field > label {
      color: var(--primary-theme-color);
      font-weight: bold;
    }
  }
  .card-patient-panel {
    .sub.header {
      margin-bottom: 30px;
      &.left-align {
        margin: 5px;
        line-height: 2.2em;
      }
    }
  }
  .card-vital-sign {
    .ReactTable .rt-thead {
      flex: unset;
    }
    .rthfc .rt-th,
    .rthfc.-sp .rthfc-th-fixed {
      background-color: unset;
    }
  }
  .badge {
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
  }
}
.ui.modal {
  .modal-profile {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        border-radius: 50%;
        width: 60%;
      }
    }
    .detail {
      div {
        line-height: 2.2em;
        font-weight: bold;
      }
    }
  }
  .modal-profile-button {
    .ui.button {
      &.primary {
        background-color: var(--primary-theme-color);
        width: 150px;
        color: white;
        border-radius: 6px;
      }

      &.back {
        background-color: #f2994a;
      }
    }
  }
}

.chat-classify-list {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 175px);

  // grid-template-columns: 115px auto;
  .left-list {
    // height: 79vh;
    overflow-y: auto;
    padding-bottom: 0.5em;
    padding-top: 2px;
    padding-right: 3px;
  }

  .classify-group {
    width: 195px;
    min-height: 65px;
    border-radius: 8px;
    background: var(--primary-theme-color);
    padding: 8px;
    margin-bottom: 5px;
    color: white;
    font-weight: "bold";
    cursor: pointer;
    // margin-right: 85px;
    &.is_active {
      background: #0072bc;
      .icon-overlay {
        color: #0072bc !important;
      }
    }
    transition: width 0.3s;
    .content {
      width: 85%;
      // height: 38px;
      overflow: hidden;
      font-weight: bold;
    }
    .icon-overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      transform: scale(0.8);
      color: var(--primary-theme-color) !important;
    }
  }
  .items {
    padding: 0 3px 8px 3px;
    width: 100%;
    transition: 0.3s ease-out;
    overflow-y: auto;
    padding-bottom: 0.9em;

    .item {
      padding: 10px 10px 20px 5px;
      border-radius: 3px;
      cursor: pointer;
      border-bottom: 1px solid #f2f2f2;
      &.active {
        background-color: rgba(114, 246, 189, 0.3) !important;
      }
      &:hover {
        background-color: rgba(135, 152, 145, 0.08);
      }
      div {
        margin-bottom: 5px;
      }
      label {
        font-weight: bold;
        color: black;
      }
    }
  }

  &.full-screen {
    .classify-group {
      width: 0;
      display: none;
    }
    .classify-group:nth-child(1) {
      height: 37px;
      min-height: auto;
      display: block;
      position: absolute;
      width: 92%;
      background-color: var(--primary-theme-color);
      .content {
        width: auto;
        display: flex;
        height: 17px;
      }
    }
    .items {
      margin-top: 47px;
      padding-left: 0;
    }
  }
}

.menu-patient-info {
  width: 50px;
  border-right: 1px solid #bdbdbd;
  height: calc(100vh - 82px);
  .ui.button {
    width: 100%;
    margin: 0;
    border-radius: 0;
    // background-color: var(--sidebar-light-bg);
    background-color: white;
    // color: var(--primary-theme-color);
    color: #4f4f4f;
    padding: 15px;
    i {
      font-size: 17px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &.active {
      background-color: var(--sidebar-light-bg);
    }
  }
}
