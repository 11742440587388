/* ReactTableFixedColumns.css react-lib */

.rthfc .rt-th,
.rthfc.-sp .rthfc-th-fixed,
.rt-td.HeaderCell.rthfc-td-fixed.rthfc-td-fixed-left{
  background-color: #2F3E53;
}

.rthfc.-sp .rthfc-th-fixed,
.rthfc.-sp .rthfc-td-fixed {
  position: -webkit-sticky;
  position: sticky !important;
  z-index: 1;
}

.rthfc .rt-th, .rthfc .rt-td{
  background: transparent;
}

.rthfc.-sp .rthfc-td-fixed{
  background: #FFFFFF
}

.ReactTable .rt-tr.blueSelectedRow .rt-td.rthfc-td-fixed{ 
  background: #CCE2FF;
}

.ReactTable.blueSelectedRow .rt-tr-group .rt-tr-group:hover{
  background: #EEF5FE
}

.ReactTable.fix-height .rt-tr-group{
  height: 32px;
}

.ReactTable.fix-height .rt-td.rthfc-td-fixed.rthfc-td-fixed-left.rthfc-td-fixed-left-last{
  padding-top: 6px;
  padding-bottom: 3px;
}